import 'jquery-validation/dist/jquery.validate.min';
import 'jquery-validation/dist/additional-methods.min';
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
          click: {
            submitAjax: 'submitAjax',
            submit: 'submit',
            append: 'append',
            remove: 'remove',
          },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
      var $ = jQuery;
      var $msg_error = 'Ce champ est obligatoire';
      var $msg_error_email = 'Saisir une adresse email valide (ex: email@exemple.com)';
      var $msg_error_extension = 'Types dde fichiers autorisés .jpg, .jpeg, .png .pdf';
      var $msg_error_filesize = 'Fichier trop volumineux ! taille maximale 5 Mo';

      $.validator.addMethod('filesize', function (value, element, param) {
        return this.optional(element) || (element.files[0].size <= param)
      }, $msg_error_filesize);

      if(this.getData('form')) {
        const form = this.getData('form');
        $(form).validate({
          ignore: [],
          lang: 'fr',
          errorElement : 'div',
          errorPlacement: function(error, element) {
            var placement = $(element).data('error');
            if (placement) {
              $(placement).append(error);
            } else {
              error.insertAfter(element);
            }
          },
        });
        const required = this.$('required');
        const rules = [];
        required.forEach(function(element) {
          $(element).rules('add', { required: true, messages : { required : $msg_error } });
          if ($(element).attr('type') == 'email') {
            $(element).rules('add', { email: true, messages : { email : $msg_error_email } });
          }
        });

        const files = this.$('file');
        files.forEach(function(element) {
          if($(element).attr('type') == 'file') {
            $(element).rules('add', { extension: 'jpeg|jpg|png|pdf', filesize : 5242880, messages : { extension : $msg_error_extension } });
          }
        });
      }

    }

    submitAjax(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');
      if(form.valid()) {
        var data = new FormData(form[0]);
        
        $.ajax({
          url: this.ajaxurl,
          type: 'POST',
          // data: form.serialize(),
          data: data,
          processData: false,
          contentType: false,
          beforeSend: function () {
            form.find('button').attr('disabled', true);
          },
          success: function( response ){
            if(response.redirectto) {
              window.location.href = response.redirectto;
            }
            if(response == 'success' || response.success === true) {
              form.find('.c-form_msg').addClass('u-margin-top-small');
              if(response.data && response.data.message) {
                form.find('.c-form_msg').html(response.data.message).fadeIn(1000);
              } else if(form.find('.c-form_msg').attr('data-success')) {
                form.find('.c-form_msg').html(form.find('.c-form_msg').attr('data-success')).fadeIn(1000);
              } else {
                form.find('.c-form_msg').html('<p>Merci, votre demande a bien été envoyée ! Nous allons la traiter dans les meilleurs délais.</p>').fadeIn(1000);
              }
              form.find('.c-form_msg').removeClass('error');
              form.find('.c-form_msg').addClass('success');
              form[0].reset();
              
              if (response.data && response.data.link) {
                form.find('.c-form_msg_redirect').fadeIn(1000);
                var count = 5;
                setInterval(function () {
                  count--;
                  if (count > 1) {
                    form.find('.c-form_msg_redirect').html(`Vous serez redirigé dans ${count} secondes`);
                  }
                  else {
                    form.find('.c-form_msg_redirect').html(`Vous serez redirigé dans ${count} seconde`);
                  }
                  if (count == 0) {
                    window.location.href = response.data.link;
                  }
                }, 1000);
              }
            } else {
              form.find('.c-form_msg').addClass('u-margin-top-small');
              form.find('.c-form_msg').html('<p>Oops! Une erreur est survenue lors de l’envoi du message. Merci de réessayer.</p>').fadeIn(1000);
              form.find('.c-form_msg').removeClass('success');
              form.find('.c-form_msg').addClass('error');
            }
          },
          complete: function () {
            form.find('button').attr('disabled', false);
          },
        });
      } else {
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html('<p>Oops! Veuillez remplir les champs.</p>').fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }

    submit(e) {
      const target = e.currentTarget;
      var form = jQuery(target).parents('form');
      if(form.valid()) {
        form.submit();
      }else {
        form.find('.c-form_msg').addClass('u-margin-top-small');
        form.find('.c-form_msg').html('<p>Oops! Veuillez remplir les champs.</p>').fadeIn(1000);
        form.find('.c-form_msg').removeClass('success');
        form.find('.c-form_msg').addClass('error');
      }
    }

    append(e) {
      const target = e.currentTarget;
      let container = $(target).data('append');
      let template = $(target).data('template');
      let targetContainerName = $(target).data('target');
      var form = jQuery(target).parents('form');
      let $this = this;
      form.find('.c-form_msg-' + targetContainerName).html('').hide(1000);
      $('[data-form="remove"][data-target="' + targetContainerName + '"]').fadeIn(1000);
      $.ajax({
        url: this.ajaxurl,
        type: 'POST',
        data: {action: 'form_append', template: template},
        success: function( response ){
          $(container).append(response.content);
          $this.call('update', false, 'Scroll'); // update Locomotive Scroll
        },
      });
    }

    remove(e) {
      const target = e.currentTarget;
      let targetContainerName = $(target).data('target');
      var form = jQuery(target).parents('form');
      let targetContainer = $('[data-remove="' + targetContainerName + '"]');
      let $this = this;
      if (targetContainer.length > 1) {
        form.find('.c-form_msg-' + targetContainerName).html('').hide(1000);
        targetContainer.last().remove();
        $this.call('update', false, 'Scroll'); // update Locomotive Scroll
        if (targetContainer.length === 2) {
          $(target).hide();
        }
      }
      else {
        form.find('.c-form_msg-' + targetContainerName).html('<p>Il est impossible de supprimer toutes les lignes</p>').fadeIn(1000);
        form.find('.c-form_msg-' + targetContainerName).removeClass('success');
        form.find('.c-form_msg-' + targetContainerName).addClass('error');
      }
    }
}
