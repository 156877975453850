import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            reloadOnContextChange: true,
            touchMultiplier: 4,
            tablet: {
              smooth: true,
              //multiplier: 30,
            },
            smartphone: {
              smooth: true,
              //multiplier: 30,
            }
        });

        setTimeout(() => {
          this.scroll.update();
          let width = window.innerWidth;
          if (width < 999) {
            if ($('.b-single.-offre .b-single_side').length) {
              $('.b-single.-offre .b-single_side').removeAttr('data-scroll-sticky');
            }
          }

          /* Ninja forms fix temporaire */
          // if ($('.nf-form-cont').length && !$('div').hasClass('nf-form-wrap')) {
          //   location.reload();
          // }
        }, 500);

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
        });

        this.scroll.on('scroll', (args) => {
            // console.log(args.scroll);
        })
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target);
    }

    update() {
      this.scroll.update();
    }
}
