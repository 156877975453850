import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          focusin: {
            input: 'active',
          },
          focusout: {
            input: 'desactive',
          }
        }
    }

    init() {
      $( '.inputfile' ).each( function() {
        var $input	 = $( this ),
        $label	 = $input.next( 'label' ),
        labelVal = $label.html();

        $input.on( 'change', function( e ) {
          var fileName = '';

          if( this.files && this.files.length > 1 )
          fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
          else if( e.target.value )
          fileName = e.target.value.split( '\\' ).pop();

          if( fileName )
          $label.find( '.title' ).html( fileName );
          else
          $label.html( labelVal );
        });

        // Firefox bug fix
        $input
        .on( 'focus', function(){ $input.addClass( 'has-focus' ); })
        .on( 'blur', function(){ $input.removeClass( 'has-focus' ); });
      });
    }

    active(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      item.classList.add('is-active');
    }

    desactive(e) {
      const target = e.currentTarget;
      const item = this.parent('item', target);
      if(target.value == "") {
        item.classList.remove('is-active');
      }
    }
}
