import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                flap: 'toggleFlap',
                close: 'close',
            }
        }
    }

    init() {

    }

    toggleFlap(e) {
        const html = document.querySelector('html');
        if(html.classList.contains('has-flapOpen')) {
            html.classList.remove('has-flapOpen');
        } else {
          if (html.classList.contains('has-navOpen')) {
            html.classList.remove('has-navOpen');
            document.getElementsByClassName('c-header_nav_burger')[0].classList.remove('is-active');
          }
            html.classList.add('has-flapOpen');
        }
    }

    close() {
        const html = document.querySelector('html');
        if(html.classList.contains('has-flapOpen')) {
            html.classList.remove('has-flapOpen');
        }
    }
}
