import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            open: 'open',
            close: 'close',
          }
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
      if(this.getData('open')) {
        let $cookie = false;
        jQuery.ajax({
          url: this.ajaxurl,
          type: 'POST',
          data: {'action': 'check_alerte_cookie'},
          success: function( response ){
            if (!response) {
              const html = document.querySelector('html');
              setTimeout(function(){ html.classList.add('has-modalOpen'); }, 6000);
            }
          },
        });
      }
    }

    open() {

    }

    close() {
      const html = document.querySelector('html');
      if(html.classList.contains('has-modalOpen')) {
          html.classList.remove('has-modalOpen');
          jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {'action': 'hide_alerte'},
            success: function( response ){
              
            },
          });
      }
    }
}
